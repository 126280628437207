<template>
<div v-if="perms.includes('Paramètrage') || perms.includes('Admin')">
   <div v-if="loading">
      <div id="loading-bg">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
    <b-card v-else>
    <b-media no-body>
    <b-media-aside>
      <b-link>
        <b-img
          v-if="imagepreview" 
          :src="imagepreview" 
          height="80"
        />
        <!-- ${path} -->
         <b-img
          v-else-if="logo" 
          :src="`${path}${logo}`" 
          height="80"
        />
      </b-link>
    </b-media-aside>

    <b-media-body class="mt-75 ml-75">
       <input type="file" class="hidden" ref="updateImgInput" @change="imageSelected" accept="image/*">
      
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="sm"
        class="mb-75 mr-75"
        @click="$refs.updateImgInput.click()"
      >
      <!-- $refs.refInputEl.$el.click() -->
        Importer votre Logo
      </b-button>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        size="sm"
        class="mb-75 mr-75"
        @click="imagepreview = null"
      >
        Annuler
      </b-button> 
      <b-card-text>Type JPG, GIF or PNG. Max size of 800kB</b-card-text>
    </b-media-body>
  </b-media> 
  <br/>
  <b-media no-body>
    <b-media-aside>
      <b-link>
        <b-img
          v-if="footerpreview" 
          :src="footerpreview" 
          height="80"
        />
        <!-- ${path} -->
         <b-img
          v-else-if="footer" 
          :src="`${path}${footer}`" 
          height="80"
        />
      </b-link>
    </b-media-aside>

    <b-media-body class="mt-75 ml-75">
       <input type="file" class="hidden" ref="updateImgFooter" @change="FooterSelected" accept="image/*">
      
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="sm"
        class="mb-75 mr-75"
        @click="$refs.updateImgFooter.click()"
      >
      <!-- $refs.refInputEl.$el.click() -->
        Importer votre Footer (PDF)
      </b-button>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        size="sm"
        class="mb-75 mr-75"
        @click="footerpreview = null"
      >
        Annuler
      </b-button> 
      <b-card-text>Type JPG, GIF or PNG. Max size of 800kB</b-card-text>
    </b-media-body>
  </b-media> 

  <br/>
  <b-media no-body>
    <b-media-aside>
      <b-link>
        <b-img
          v-if="bgpreview" 
          :src="bgpreview" 
          height="80"
        />
        <!-- ${path} -->
         <b-img
          v-else-if="bg" 
          :src="`${path}${bg}`" 
          height="80"
        />
      </b-link>
    </b-media-aside>

    <b-media-body class="mt-75 ml-75">
       <input type="file" class="hidden" ref="updateImgBG" @change="BGSelected" accept="image/*">
      
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="sm"
        class="mb-75 mr-75"
        @click="$refs.updateImgBG.click()"
      >
      <!-- $refs.refInputEl.$el.click() -->
        Importer votre Background (PDF)
      </b-button>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        size="sm"
        class="mb-75 mr-75"
        @click="bgpreview= null"
      >
        Annuler
      </b-button> 
      <b-card-text>Type JPG, GIF or PNG. Max size of 800kB</b-card-text>
    </b-media-body>
  </b-media> 

    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nom Société"
            label-for="account-societe"
          >
            <b-form-input
              v-model="form.NomSociete"
              name="nameSociete"
              placeholder="Nom Société"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="ICE"
            label-for="account-ice"
          >
          <!-- v-model="optionsLocal.username" -->
            <b-form-input
              v-model="form.ice"
              placeholder="ICE"
              name="ice"
            />
          </b-form-group>
        </b-col>
         <b-col sm="12">
            <label for="textarea">Description</label>
                <b-form-textarea
                    id="textarea"
                    rows="1"
                    max-rows="8"
                    v-model="form.desc"
                />
        </b-col><br/>

        <b-col sm="6">
          <b-form-group
            label="Téléphone"
            label-for="account-phone"
          >
            <b-form-input
              v-model="form.phone"
              name="phone"
              placeholder="0754236598"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Email"
            label-for="account-email"
          >
            <b-form-input
              v-model="form.email"
              placeholder="p2m@gmail.com"
              type="email"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="R.C"
            label-for="account-rc"
          >
            <b-form-input
              v-model="form.rc"
              placeholder="2589/ AGADIR"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="T.P"
            label-for="account-tp"
          >
            <b-form-input
              v-model="form.tp"
              placeholder="225500114"

            />
          </b-form-group>
        </b-col>
         <b-col sm="6">
          <b-form-group
            label="I.F"
            label-for="account-if"
          >
            <b-form-input
              v-model="form.if"
              placeholder="25983614"

            />
          </b-form-group>
        </b-col>
         <b-col sm="6">
          <b-form-group
            label="C.N.S.S"
            label-for="account-cnss"
          >
            <b-form-input
              v-model="form.cnss"
              placeholder="254143"

            />
          </b-form-group>
        </b-col>
         <b-col sm="12">
            <label for="textarea-no-auto-shrink">Adresse</label>
                <b-form-textarea
                    id="textarea-no-auto-shrink"
                    placeholder="Auto height (no-shrink) textarea"
                    rows="2"
                    max-rows="8"
                    no-auto-shrink
                    v-model="form.adresse"
                />
        </b-col>

       

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="AddSettings()"
          >
            Enregistrer
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Annuler
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
    </b-card>
</div>
<div v-else>
    <not-authoriz></not-authoriz>
    </div>
</template>

<script>
import config from '@/config'
import {  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, 
BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,BTabs, BTab ,BFormTextarea
} from 'bootstrap-vue'

// import AccountSettingPassword from './pages/account-setting/AccountSettingPassword.vue'
// import AccountSettingInformation from './pages/account-setting/AccountSettingInformation.vue'
// import AccountSettingSocial from './pages/account-setting/AccountSettingSocial.vue'
// import AccountSettingNotification from './pages/account-setting/AccountSettingNotification.vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import NotAuthoriz from '../NotAuthoriz.vue'

export default {
  components: {
    BTabs,
    BTab, BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormTextarea,
    NotAuthoriz
   
  },
   mixins: [heightTransition],
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      perms:[],
      path:config.path,
       loading:false,
        options: {},
        image:null,
        imagepreview:null,
        footer:null,
        footerpreview:null,
        bg:null,
        bgpreview:null,
        logo:null,
        UserID:{},
        form: new Form({
            id:0,
            ice:'',
            NomSociete:'',
            phone:'',
            adresse:'',
            email:'',
            rc:'',
            tp:'',
            if:'',
            cnss:'',
            desc:'',
            file:{
              size:'',
              height:'',
              width:''
            }
            
        }),
      
      items: [],
       nextTodoId: 0,
       idFouni:null,
       isTrue:true,

    
    }
  },
   methods: {
    imageSelected(e){
        this.image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(this.image);
        reader.onload = e => {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function(){
            // if(this.width < 900 || this.height<100){
            //   console.log('not exacte');
            // }else{
            //   console.log('exacte');
            // }
            // console.log(this.height+10);
          };
          this.imagepreview = e.target.result;
          console.log(this.image.src)
            };
        

    },
    FooterSelected(e){
        this.footer = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(this.footer);
        reader.onload = e => {
          this.footerpreview = e.target.result;
            };
    },
    BGSelected(e){
        this.bg = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(this.bg);
        reader.onload = e => {
          this.bgpreview = e.target.result;
            };
    },
    resetForm() {
        this.form.ice=''
        this.form.NomSociete=''
        this.form.phone=''
        this.form.adresse=''
        this.form.email=''
        this.form.rc=''
        this.form.tp=''
        this.form.if=''
        this.form.cnss=''
        this.image=''
        this.imagepreview=''
        this.footer=''
        this.footerpreview=''
        this.bg=''
        this.bgpreview=''
      // this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },

    AddSettings(){
       let data = new FormData;
          data.append('UserID', this.UserID.id);
          data.append('logo', this.image);
          data.append('footer', this.footer);
          data.append('background', this.bg);
          data.append('id', this.form.id);
          data.append('NomSociete', this.form.NomSociete.toUpperCase());
          data.append('desc', this.form.desc.toUpperCase());
          data.append('ice', this.form.ice);
          data.append('phone', this.form.phone);
          data.append('adresse', this.form.adresse);
          data.append('email', this.form.email);
          data.append('rc', this.form.rc);
          data.append('tp', this.form.tp);
          data.append('if', this.form.if);
          data.append('cnss', this.form.cnss);
          // console.log(Object.fromEntries(data))
          // console.log(this.image)
          //       let headers = {
          //     'Content-Type': 'text/plain',
          //     'X-Requested-With': 'XMLHttpRequest', 
          //     'Access-Control-Allow-Origin' : 'http://stock.storefy.ma',
          // };

        this.$http.post(`${config.API_BASE_URL}/api/auth/AddSettings`,data)
        .then(res => {       
              if(res.data=='success')
             { 
              this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Les paramètres sont bien sauvegardés',
                  showConfirmButton: false,
                  timer: 1000
                })}
       
        })
        .catch(() => {

          this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
              })
        }) 
    },

  },
  created() {
      this.perms=JSON.parse(localStorage.getItem('access'));
      this.loading = true
        this.$http.get(`${config.API_BASE_URL}/api/auth/GetSettings`)
        .then(res => { 
          this.form.fill(res.data), 
          this.logo=res.data.logo,
          this.image=res.data.logo ,
          this.footer=res.data.footer,
          this.bg=res.data.background,
          this.loading = false
          
        })
      this.UserID=JSON.parse(localStorage.getItem('userData'))
 },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  
}
</script>
<style scoped>
.col-auto {
    display: none;
}
</style>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.col-auto {
    display: none;
}
</style>